 // Your web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyB10EaSl2BYc5eyOmiRNEB9OZGhCG9PbKQ",
    authDomain: "ei-project-management.firebaseapp.com",
    databaseURL: "https://ei-project-management.firebaseio.com",
    projectId: "ei-project-management",
    storageBucket: "gs://ei-project-management.appspot.com/",
    messagingSenderId: "466453975244",
    appId: "1:466453975244:web:176979c26be06f40"
  };
  // Initialize Firebase
 export default firebaseConfig