import React from "react";
import "./dashboard.css";
import ProjectCards from "../Projects/ProjectCards/ProjectCards";
import FirebaseContext from "../../Firebase/context";
import ModalProj from "../Modal/ModalProj";

const DashBoard = () => {
  const { user } = React.useContext(FirebaseContext);

  function firstCharUpper(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return user ? (
    <>
      <div className="main-header">
        <div className="main-header__heading">
          {user ? (
          
            <>
          <h1>Hello {firstCharUpper(user.displayName)}, this is projects dashboard</h1>
              {/*<h1> “Everybody panic! Oh my God, there’s a bear loose in the Coliseum! There will be no refunds! 
               Your refund will be escaping this trap with your life! If you have a small child, use it as a shield! 
          They love tender meat! Cover your sodas! Dewie loves sugar!” – Jackie Moon - Semi-Pro</h1>*/}
            
            </>
          ) : null}
        </div>
        <div className="main-header__updates">
          {/*<WeatherPage/>*/}
          {/*<Quotes/>*/}
        </div>
      </div>

      <div className="main-cards">
        <ModalProj mode="Add"/>
      </div>

      <ProjectCards />
    </>
  ) : null;
};

export default DashBoard;
