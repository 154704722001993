import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { FirebaseContext } from "../../Firebase";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ErrorContainer } from "../../Styles/helpers";

const TodoForm = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: #ffffff;
  height: fit-content;
  justify-content: space-between;
  padding: 20px;
  max-width: 50%;
  color: dimgray;
  margin: 25px;
  list-style-type: none;
  font-size: 20px;

  input {
    color: dimgray;
    margin-top: 20px;
    font-size: 20px;
    width: 90%;
    padding: 20px;
    ::placeholder {
      color: #f0f0f0;
      filter: brightness(70%);
    }
  }
  h1 {
    margin: 0;
  }
  li {
    padding: 15px;
    line-height:20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width:990px) {
    max-width:100%
    
  }
`;
const CircleCheck = styled.div`

height: 25px;
  width: 25px;
  background:#ffffff;
  border: 1px solid ;
  border-radius: 50%;
  display: inline-block;

  &:hover{
background:transparent;
  }
  i{
   
      opacity:0;
      padding:2px;
      cursor:pointer;
      &:hover{
        opacity:1;
        
      }
  }
  `

const ToDoItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: stretch;
  align-items:center;
  border-bottom: 2px solid #f0f0f0;  
`;

const ToDo = () => {
  const { firebase, user } = useContext(FirebaseContext);

  const [todo, setTodo] = useState("");
  const [myTodos, setMyTodos] = useState("");

  const todoSchema = Yup.object().shape({
    todoText: Yup.string()
      .min(10, "10 Character Minimum")
      .required("todo is required")
  });

  const handleAddToDo = values => {
    const prevTodo = myTodos;
    const newTodo = {
      createdBy: { id: user.uid, name: user.displayName },
      createdDate: Date.now(),
      text: values.todoText,
      completed: false
    };

    const updatedTodos = [...prevTodo, newTodo];
    firebase.db.collection("todos").add(newTodo);
    setMyTodos(updatedTodos);
    for (var key in values) {
      values[key] = "";
    }
  };

  function handleComplete(todoId) {
    const todo = firebase.db.collection("todos").doc(todoId);
    todo.update({ completed: true });
  }
  function getMyToDos() {
    firebase.db
      .collection("todos")
      .orderBy("createdDate", "asc")
      .onSnapshot(handleSnapshot);
  }

  function handleSnapshot(snapshot) {
    const todos = snapshot.docs.map(todo => {
      return { id: todo.id, ...todo.data() };
    });
    setMyTodos(todos);
  }

  useEffect(() => {
    getMyToDos();
  }, []);

  let todoNodes;

  todoNodes = myTodos
    ? myTodos.map((todo, index) => {
        return todo.createdBy.name === user.displayName &&
          todo.completed === false ? (
          <ToDoItem key={index}>
          <CircleCheck ><i 
          className="fas fa-check"
          onClick={() => handleComplete(todo.id)}
        ></i></CircleCheck>
            
            <li >{todo.text}</li>
          </ToDoItem>
        ) : null;
      })
    : null;

  return (
    <TodoForm>
      <Formik
        initialValues={{
          todoText: ""
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={todoSchema}
        onSubmit={handleAddToDo}
      >
        {({ errors, touched }) => (
          <Form>
            <h1>Todosies</h1>
            <Field
              name="todoText"
              type="text"
              placeholder="Type your Todo text and press enter"
              autoComplete="off"
              
          
            />
            <ErrorContainer>
              {errors.todoText && touched.todoText && (
                <div>{errors.todoText}</div>
              )}
            </ErrorContainer>
          </Form>
        )}
      </Formik>
      <div>{todoNodes}</div>
    </TodoForm>
  );
};

export default ToDo;
