import React from 'react';
import ReactModal from 'react-modal';
import AddProject from "../Projects/AddProject/AddProject"
import Card from "../../Styles/Card"
import "./ModalProj.css"
import {ProjectBtn} from "../../Styles/Buttons"

ReactModal.setAppElement('#root')

const ModalProj = (props) => {
  const [modal,setModal] = React.useState(false);
  function openModal(){
    setModal(true)
  }
  const mode = props.mode
 
  
  function closeModal() {
   setModal(false)
  }
  return ( <>
    <ProjectBtn onClick={openModal}><i className="fas fa-plus-circle"></i>Add Project</ProjectBtn>
    <ReactModal
          isOpen={modal}
          onRequestClose={closeModal}
          overlayClassName="Overlay"
          className="Modal"
          contentLabel="Example Modal"
        >
        <Card><AddProject closeModal={closeModal} mode={mode} /></Card>
        </ReactModal>
    </> );
}
 
export default ModalProj;