import styled from "styled-components";
import { setRem, setBorder } from "./helpers";

export const PrimaryBtn = styled.a`
  text-decoration: none;
  color: dimgray;
  ${setBorder({ width: "1px", color: "dimgray" })}
  padding:${setRem(9)} ${setRem(12)};
  border-radius: 4px;
  margin-top: 10px;
  align-self: center;
  font-family: "Dosis", sans-serif;
  font-size: ${setRem(16)};
  cursor: pointer;
  letter-spacing: 1.3px;
  
  &:hover {
    background: dimgray;
    color: #fff;
    a{
      color:#fff;
      
    }
  }
  a {
    color: dimgray;
    text-decoration: none;
  }


`;


export const CommentBtn = styled.button`
  text-decoration: none;
  color: dimgray;
  ${setBorder({ width: "1px", color: "dimgray" })}
  padding:${setRem(9)} ${setRem(12)};
  border-radius: 4px;
  margin-top: 10px;
  align-self: center;
  font-family: "Dosis", sans-serif;
  font-size: ${setRem(16)};
  cursor: pointer;
  letter-spacing: 1.3px;
  
  &:hover {
    background: dimgray;
    color: #fff;
    a{
      color:#fff;
      
    }
  }
  a {
    color: dimgray;
    text-decoration: none;
  }


`;


export const SmallBtn = styled(PrimaryBtn)`
  padding: ${setRem(5)} ${setRem(6)};
  font-size: ${setRem(14)};
`;


export const ProjectBtn = styled.button`
  text-decoration: none;
  padding: ${setRem(9)} ${setRem(12)};
  font-size: ${setRem(24)};
  color: #fff;
  background: #f9cf00;
  ${setBorder({ width: "0px", color: "#fff" })}
  border-radius: 4px;
  margin-top: 10px;
  align-self: center;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
  letter-spacing: 1.3px;

  

  &:hover {
    background: #fff;
    color: #f9cf00;
  }
`;

export const AddProjectBtn = styled(ProjectBtn)`
color:#ffffff;
background:#7da4e8;
${setBorder({ width: "1px", color: "#fff" })}
font-size: ${setRem(16)};


&:hover {
    background: #fff;
    color: #7da4e8;
  }
`
