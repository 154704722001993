import styled ,{ css }  from "styled-components";
export const setColor = {
  primaryColor: "#af9a70",
  mainWhite: "#fff",
  mainBlack: "#222",
  mainGrey: "#ececec",
  lightGrey: "#f7f7f7"
};


export const setBackground = ({
  img = "https://images.pexels.com/photos/926987/pexels-photo-926987.jpeg?cs=srgb&dl=america-analysis-cellphone-926987.jpg&fm=jpg",
  color = "rgba(0,0,0,0)"
} = {}) => {
  return `background:linear-gradient(${color},${color}),
    url(${img}) center/cover fixed no-repeat;`;
};

const sizes = {
  large: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576
};
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 15}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const setFlex = ({ x = "center", y = "center" } = {}) => {
  return `display:flex;
    align-items:${y};
    justify-content:${x};`;
};

export const setRem = (number = 16) => {
  return `${number / 16}rem`;
};

export const setLetterSpacing = (number = 2) => {
  return `letter-spacing:${number}px`;
};
export const ErrorContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: flex-start;
  color: red;
`;

export const setBorder = ({
  width = "2px",
  style = "solid",
  color = "black"
} = {}) => {
  return `border:${width} ${style} ${color}`;
};

export const setTransition = ({
  property = "all",
  time = "0.5s",
  timing = "ease-in-out"
} = {}) => {
  return `transition:${property} ${time} ${timing}`;
};

export const setShadow = {
  light: "box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75)",
  dark: "box-shadow: 6px 6px 5px 0px rgba(0,0,0,0.75)",
  darkest: "box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75)"
};
