import React from 'react';
import ReactTooltip from 'react-tooltip'
import "./Emoji.css"
const Emoji = props => (
  <>
      <span
        onClick={props.clicked}
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
        data-tip
        data-for={props.id}
      >
        {props.symbol}
      </span>
      <ReactTooltip className="style" id='complete' type='success'>
      <span>Mark as completed</span>
    </ReactTooltip>

    <ReactTooltip className="style" id='delete' type='error' effect='solid'>
      <span>Danger cannot undo</span>
      
    </ReactTooltip>
    <ReactTooltip className="style"id='reset' type='warning' effect='solid'>
    <span>you jumped the gun send it back</span>
  </ReactTooltip>
  <ReactTooltip className="style" id='pending' type='info' effect='solid'>
    <span>Mark as pending</span>
  </ReactTooltip>
 
  </>
  )

  export default Emoji