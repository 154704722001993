import React from "react";
import { Tabs } from "@yazanaabed/react-tabs";
import { withRouter } from "react-router-dom";
import validateAddProject from "../../../Auth/ValidateAddProject";
import "./AddProject.css";
import FirebaseContext from "../../../Firebase/context";
import useFormValidation from "../../../Auth/useFormValidation";
import { AddProjectBtn } from "../../../Styles/Buttons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Emoji from "../../Emoji/Emoji";

function AddProject(props) {
  let INITIAL_PROJECT_STATE = {
    companyName: "",
    companyContactName: "",
    companyEmail: "",
    companyPhone: "",
    category: "",
    projectDescription: "",
    itCompanyName: "",
    itContactName: "",
    itEmail: "",
    itPhone: "",
    itMessage: "",
    erpName: "",
    erpContactName: "",
    erpEmail: "",
    erpPhone: "",
    erpDescription: ""
  };

  const STATE = props.project;

  if (STATE) {
    INITIAL_PROJECT_STATE = STATE;
  }

  const project = props.project;
  const { firebase, user } = React.useContext(FirebaseContext);
  const { handleSubmit, handleChange, values, errors } = useFormValidation(
    INITIAL_PROJECT_STATE,
    validateAddProject,
    handleAddProject
  );

  const options = {
    autoClose: 2000,
    type: toast.TYPE.SUCCESS,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT
    // and so on ...
  };
  function Notify() {
    toast.success("Project has been updated", options);
  }

  function handleAddProject() {
    if (!user) {
      props.history.push("/");
    } else {
      const {
        companyName,
        companyContactName,
        companyEmail,
        companyPhone,
        category,
        projectDescription,
        itCompanyName,
        itContactName,
        itEmail,
        itPhone,
        itMessage,
        erpName,
        erpContactName,
        erpEmail,
        erpPhone,
        erpDescription
      } = values;
      const newProject = {
        companyName,
        companyContactName,
        companyEmail,
        companyPhone,
        category,
        projectDescription,
        addedBy: {
          id: user.uid,
          name: user.displayName
        },
        tasks: [],
        comments: [],
        created: Date.now(),
        itCompanyName,
        itContactName,
        itEmail,
        itPhone,
        itMessage,
        erpName,
        erpContactName,
        erpEmail,
        erpPhone,
        erpDescription,
        projectComplete: false,
        pending:false,
        assignedTo:""
      };
      firebase.db.collection("projects").add(newProject);
      for (var key in values) {
        values[key] = "";
      }
    }
  }

  function handleUpdateProject() {
    const {
      companyName,
      companyContactName,
      companyEmail,
      companyPhone,
      category,
      projectDescription,
      itCompanyName,
      itContactName,
      itEmail,
      itPhone,
      itMessage,
      erpName,
      erpContactName,
      erpEmail,
      erpPhone,
      erpDescription
    } = values;
    const updatedProject = {
      companyName,
      companyContactName,
      companyEmail,
      companyPhone,
      category,
      projectDescription,
      addedBy: {
        id: user.uid,
        name: user.displayName
      },
      itCompanyName,
      itContactName,
      itEmail,
      itPhone,
      itMessage,
      erpName,
      erpContactName,
      erpEmail,
      erpPhone,
      erpDescription
    };
    const projectRef = firebase.db.collection("projects").doc(project.id);
    projectRef.update(updatedProject).then(Notify());
  }

  return (
    <>
      {" "}
      <ToastContainer />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "0",
          color: "grey",
          cursor: "pointer"
        }}
      >
      {props.project ? null:(
        <Emoji
        clicked={props.closeModal}
        label="closeModal"
        id="closeModal"
        symbol="✖️"
      />  
      )}
        
      </div>
      <Tabs
        activeTab={{
          id: "tab1"
        }}
      >
        <form onSubmit={handleSubmit}>
          <Tabs.Tab id="tab1" title="Company">
            <div style={{ padding: 10 }}>
              <ul className="flex-outer">
                <li>
                  <label htmlFor="Company Name">Name</label>
                  <input
                    onChange={handleChange}
                    value={values.companyName}
                    name="companyName"
                    type="text"
                    id="company-name"
                    placeholder="Enter your company name here"
                    autoComplete="off"
                    className={errors.companyName && "input__alert"}
                  />
                </li>
                {errors.companyName && (
                  <li className="error-input">{errors.companyName}</li>
                )}
                <li>
                  <label htmlFor="Contact Name">Contact</label>
                  <input
                    onChange={handleChange}
                    value={values.companyContactName}
                    name="companyContactName"
                    type="text"
                    id="company-contact-name"
                    placeholder="Enter your company contact name here"
                    autoComplete="off"
                    className={errors.companyContactName && "input__alert"}
                  />
                </li>
                {errors.companyContactName && (
                  <li className="error-input">{errors.companyContactName}</li>
                )}
                <li>
                  <label htmlFor="email">Email</label>
                  <input
                    onChange={handleChange}
                    value={values.companyEmail}
                    name="companyEmail"
                    type="email"
                    id="company-email"
                    placeholder="Enter your main company contact email here"
                    autoComplete="off"
                  />
                </li>
                {errors.companyEmail && (
                  <li className="error-input">{errors.companyEmail}</li>
                )}
                <li>
                  <label htmlFor="phone">Phone</label>
                  <input
                    onChange={handleChange}
                    value={values.companyPhone}
                    name="companyPhone"
                    type="tel"
                    id="company-phone"
                    placeholder="Enter your main contact phone no here"
                    autoComplete="off"
                  />
                </li>
                {errors.companyPhone && (
                  <li className="error-input">{errors.companyPhone}</li>
                )}
                <li>
                  <label htmlFor="category">Project Type</label>
                  <select
                    className="select-category"
                    onChange={handleChange}
                    name="category"
                    id="category"
                    value={values.category}
                    autoComplete="off"
                  >
                    <option />
                    <option>Project</option>
                    <option>Service</option>
                  </select>
                </li>
                {errors.category && (
                  <li className="error-input">{errors.category}</li>
                )}

                <li>
                  <label htmlFor="projectDesctiption">Description</label>
                  <textarea
                    onChange={handleChange}
                    value={values.projectDescription}
                    name="projectDescription"
                    rows="6"
                    id="project description"
                    placeholder="Enter as much detail as possible here Flows, Modules, Custom Integration etc don't hold back the more the merrier. i'm talking to you Rory..."
                    autoComplete="off"
                  />
                </li>
                {errors.projectDescription && (
                  <li className="error-input">{errors.projectDescription}</li>
                )}
              </ul>
            </div>
          </Tabs.Tab>
          <Tabs.Tab id="tab2" title="IT">
            <div style={{ padding: 10 }}>
              <ul className="flex-outer">
                <li>
                  <label htmlFor="IT Company Name">Name</label>
                  <input
                    onChange={handleChange}
                    value={values.itCompanyName}
                    name="itCompanyName"
                    type="text"
                    id="it-company-name"
                    placeholder="Enter your IT company name here"
                    autoComplete="off"
                    className={errors.itCompanyName && "input__alert"}
                  />
                </li>
                {errors.itCompanyName && (
                  <li className="error-input">{errors.itCompanyName}</li>
                )}
                <li>
                  <label htmlFor="IT Contact Name">Contact</label>
                  <input
                    onChange={handleChange}
                    value={values.itContactName}
                    name="itContactName"
                    type="text"
                    id="it-contact-name"
                    placeholder="Enter your IT contact name here"
                    autoComplete="off"
                    className={errors.itContactName && "input__alert"}
                  />
                </li>
                {errors.itContactName && (
                  <li className="error-input">{errors.itContactName}</li>
                )}
                <li>
                  <label htmlFor="IT Email">Email</label>
                  <input
                    onChange={handleChange}
                    value={values.itEmail}
                    name="itEmail"
                    type="email"
                    id="it-email"
                    placeholder="Enter your main IT contact email here"
                    autoComplete="off"
                  />
                </li>
                {errors.itEmail && (
                  <li className="error-input">{errors.itEmail}</li>
                )}
                <li>
                  <label htmlFor="IT Phone">Phone</label>
                  <input
                    onChange={handleChange}
                    value={values.itPhone}
                    name="itPhone"
                    type="tel"
                    id="it-phone"
                    placeholder="Enter your main IT contact phone no here"
                    autoComplete="off"
                  />
                </li>
                {errors.itPhone && (
                  <li className="error-input">{errors.itPhone}</li>
                )}

                <li>
                  <label htmlFor="IT Message">Description</label>
                  <textarea
                    onChange={handleChange}
                    value={values.itMessage}
                    name="itMessage"
                    rows="6"
                    id="it-message"
                    placeholder="Enter anything specific to the IT company here"
                    autoComplete="off"
                  />
                </li>
                {errors.itMessage && (
                  <li className="error-input">{errors.itMessage}</li>
                )}
              </ul>
            </div>
          </Tabs.Tab>
          <Tabs.Tab id="tab3" title="ERP">
            <div style={{ padding: 10 }}>
              {" "}
              <ul className="flex-outer">
                <li>
                  <label htmlFor="ERP Name">Name</label>
                  <input
                    onChange={handleChange}
                    value={values.erpName}
                    name="erpName"
                    type="text"
                    id="erp-name"
                    placeholder="Enter your ERP system name here"
                    autoComplete="off"
                    className={errors.erpName && "input__alert"}
                  />
                </li>
                {errors.erpName && (
                  <li className="error-input">{errors.erpName}</li>
                )}
                <li>
                  <label htmlFor="ERP Contact Name">Contact</label>
                  <input
                    onChange={handleChange}
                    value={values.erpContactName}
                    name="erpContactName"
                    type="text"
                    id="erp-contact-name"
                    placeholder="Enter your ERP contact name here"
                    autoComplete="off"
                    className={errors.erpContactName && "input__alert"}
                  />
                </li>
                {errors.erpContactName && (
                  <li className="error-input">{errors.erpContactName}</li>
                )}
                <li>
                  <label htmlFor="ERP Email">Email</label>
                  <input
                    onChange={handleChange}
                    value={values.erpEmail}
                    name="erpEmail"
                    type="email"
                    id="erp-email"
                    placeholder="Enter your main ERP contact email here"
                    autoComplete="off"
                  />
                </li>
                {errors.erpEmail && (
                  <li className="error-input">{errors.erpEmail}</li>
                )}
                <li>
                  <label htmlFor="ERP Phone">Phone</label>
                  <input
                    onChange={handleChange}
                    value={values.erpPhone}
                    name="erpPhone"
                    type="tel"
                    id="erp-phone"
                    placeholder="Enter your main ERP contact phone no here"
                    autoComplete="off"
                  />
                </li>
                {errors.erpPhone && (
                  <li className="error-input">{errors.erpPhone}</li>
                )}

                <li>
                  <label htmlFor="ERP Message">Description</label>
                  <textarea
                    onChange={handleChange}
                    value={values.erpDescription}
                    name="erpDescription"
                    rows="6"
                    id="erp-description"
                    placeholder="Enter anything specific to the ERP setup here"
                    autoComplete="off"
                  />
                </li>
                {errors.erpDescription && (
                  <li className="error-input">{errors.erpDescription}</li>
                )}
              </ul>
            </div>
          </Tabs.Tab>
          <li style={{ listStyleType: "none" }}>
            {props.project ? null : (
              <AddProjectBtn type="submit">Add Project</AddProjectBtn>
            )}
          </li>
        </form>
        {props.project ? (
          <AddProjectBtn onClick={handleUpdateProject}> Save Changes</AddProjectBtn>
        ) : null}
      </Tabs>
    </>
  );
}

export default withRouter(AddProject);
