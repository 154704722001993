import React from "react";
import ProjectCardItem from "../ProjectCardItem/ProjectCardItem";
import FirebaseContext from "../../../Firebase/context";
import Spinner from "../../Spinner/Spinner";
import ProjectOverview from "../../../Styles/ProjectOverview";
import styled from "styled-components";

const ProjectCount = styled.span`
  color: #fff;
  background: transparent;
  padding: 0px 5px 0px 5px;

  width: fit-content;
  border: 1px solid;
`;

const ShowHide = styled.span`
  cursor: pointer;
`;
const initialToggleState = {
  showPending: false,
  showProject: false,
  showService: false,
  setShowComplete: false
};

const EI_TOGGLE_STATE = "EI_TOGGLE_STATE";
const storedToggleState = toggleMap => {
  localStorage.setItem(EI_TOGGLE_STATE, JSON.stringify(toggleMap));
};
const readStoredToggleState = () => {
  const toggleMap = JSON.parse(localStorage.getItem(EI_TOGGLE_STATE));
  return toggleMap ? toggleMap : initialToggleState;
};

const ProjectCards = props => {
  const storedToggles = readStoredToggleState();
  const { firebase } = React.useContext(FirebaseContext);
  const [projects, setProjects] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [showPending, setShowPending] = React.useState(
    storedToggles.showPending
  );
  const [showComplete, setShowComplete] = React.useState(
    storedToggles.showComplete
  );
  const [showProject, setShowProject] = React.useState(
    storedToggles.showProject
  );
  const [showService, setShowService] = React.useState(
    storedToggles.showService
  );

  React.useEffect(() => {
    storedToggleState({ showPending, showProject, showService, showComplete });
  }, [showPending, showProject, showService, showComplete]);

  React.useEffect(() => {
    getProjects();
  }, []);

  function getProjects() {
    firebase.db
      .collection("projects")
      .orderBy("created", "desc")
      .onSnapshot(handleSnapshot);
  }

  function handleSnapshot(snapshot) {
    const projects = snapshot.docs.map(project => {
      return { id: project.id, ...project.data() };
    });

    setProjects(projects);
    setSpinner(false);
  }

  function handleShowPending() {
    setShowPending(!showPending);
  }
  function handleShowComplete() {
    setShowComplete(!showComplete);
  }
  function handleShowProjects() {
    setShowProject(!showProject);
  }
  function handleShowServices() {
    setShowService(!showService);
  }

  return spinner ? (
    <ProjectOverview>
      <Spinner />
    </ProjectOverview>
  ) : (
    <>
      <h1>
        Projects{" "}
        <ProjectCount>
          {" "}
          {
            projects.filter(val => {
              return (
                val.category === "Project" &&
                val.projectComplete === false &&
                val.pending === false
              );
            }).length
          }
        </ProjectCount>
        <ShowHide onClick={handleShowProjects}>
          {" "}
          {showProject ? (
            <i className="fas fa-caret-up"></i>
          ) : (
            <i className="fas fa-caret-down"></i>
          )}
        </ShowHide>
        <hr />
      </h1>
      <ProjectOverview>
        {showProject
          ? projects.map(project => {
              return project.category === "Project" &&
                project.projectComplete === false &&
                project.pending === false ? (
                <ProjectCardItem
                  key={project.id}
                  showOptions={true}
                  project={project}
                />
              ) : null;
            })
          : null}
      </ProjectOverview>
      <h1>
        Services{" "}
        <ProjectCount>
          {" "}
          {
            projects.filter(val => {
              return (
                val.category === "Service" &&
                val.projectComplete === false &&
                val.pending === false
              );
            }).length
          }
        </ProjectCount>
        <ShowHide onClick={handleShowServices}>
          {" "}
          {showService ? (
            <i className="fas fa-caret-up"></i>
          ) : (
            <i className="fas fa-caret-down"></i>
          )}
        </ShowHide>
        <hr />
      </h1>

      <ProjectOverview>
        {showService
          ? projects.map(project => {
              return project.category === "Service" &&
                project.projectComplete === false &&
                project.pending === false ? (
                <ProjectCardItem
                  key={project.id}
                  showOptions={true}
                  project={project}
                />
              ) : null;
            })
          : null}
      </ProjectOverview>
      <h1>
        Pending{" "}
        <ProjectCount>
          {
            projects.filter(val => {
              return val.pending === true && val.projectComplete === false;
            }).length
          }
        </ProjectCount>
        <ShowHide onClick={handleShowPending}>
          {" "}
          {showPending ? (
            <i className="fas fa-caret-up"></i>
          ) : (
            <i className="fas fa-caret-down"></i>
          )}
        </ShowHide>
        <hr />
      </h1>
      <ProjectOverview>
        {showPending
          ? projects.map(project => {
              return project.pending === true &&
                project.projectComplete === false ? (
                <ProjectCardItem
                  key={project.id}
                  showOptions={true}
                  project={project}
                />
              ) : null;
            })
          : null}
      </ProjectOverview>

      <h1>
        Completed{" "}
        <ProjectCount>
          {
            projects.filter(val => {
              return val.projectComplete === true;
            }).length
          }
        </ProjectCount>
        <ShowHide onClick={handleShowComplete}>
          {" "}
          {showComplete ? (
            <i className="fas fa-caret-up"></i>
          ) : (
            <i className="fas fa-caret-down"></i>
          )}
        </ShowHide>
        <hr />
      </h1>
      <ProjectOverview>
        {showComplete
          ? projects.map(project => {
              return project.projectComplete === true ? (
                <ProjectCardItem
                  key={project.id}
                  showOptions={true}
                  project={project}
                />
              ) : null;
            })
          : null}
      </ProjectOverview>
    </>
  );
};

export default ProjectCards;
