import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/dashboard"
import ResetPassword from "./Auth/ResetPassword"
import Login from "./Auth/Login"
import useAuth from "./Auth/useAuth"
import firebase,{FirebaseContext} from "./Firebase"
import SearchProjects from "./components/Projects/SearchProjects/SearchProjects"
import SideBar from "./components/NavItems/Sidebar/Sidebar"
import Header from "./components/NavItems/Header/Header"
import Footer from "./components/NavItems/Footer/Footer"
import ProjectDetail from "./components/Projects/ProjectDetail/ProjectDetail"
import AddProjectFormik from "./components/Projects/AddProject/AddProjectFormik"
import ToDo from "../src/components/ToDo/ToDo"
import NotFound from "../src/components/NotFound/NotFound"

function App() {
  const user = useAuth()
  return user ? (
    <BrowserRouter>

    <FirebaseContext.Provider value={{user,firebase}}>
    <div className="grid-container">
    <Header/>
    <SideBar/>
    <main className="main">
      <Switch>

      <Route path="/dashboard" component={Dashboard}/>
      <Route path="/search" component={SearchProjects}/>
      <Route path="/projects" component={SearchProjects}/>
      <Route path="/addProject" component={AddProjectFormik}/>
      <Route path="/project/:projectId" component={ProjectDetail}/>
      <Route path="/mytodosies" component={ToDo} />
      <Route component={NotFound}/>
      </Switch>
      </main>
      <Footer/>
      </div>
      </FirebaseContext.Provider>
  
    </BrowserRouter>
 
  ):(
    <BrowserRouter>
    <FirebaseContext.Provider value={{user,firebase}}>
    
   
 
      <Switch>
      <Route exact path="/" component={Login}/>
    
      <Route path="/reset" component={ResetPassword}/>
    
      </Switch>
     
      </FirebaseContext.Provider>
    </BrowserRouter>
  );
}

export default App;
