import React, { useReducer, createContext } from "react";

export const Store = createContext();

const initialState = {
  projects: []
};

function reducer(state, action) {
  
  switch (action.type) {
  case "FETCH_DATA":
      console.log('action.payload', action.payload);
    return { ...state, projects: action.payload };
 
    case "ADD_PROJECT":
        console.log("this is inside the ADD_PROJECT",action.payload);
        return{
            ...state,
            projects: action.payload
        }
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {
    state: state,
    dispatch: dispatch
  };
  return (
    <Store.Provider value={value}>{props.children}</Store.Provider>
  );
}
