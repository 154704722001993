import React from 'react';
import { FirebaseContext } from '../../../Firebase';
import {NavLink,withRouter} from "react-router-dom"
import "./Header.css"

const Header = (props) => {
    const {user , firebase} = React.useContext(FirebaseContext)
    function logout(){

    firebase.logout()
    props.history.push("/")
  }

    function firstCharUpper(string) 
  {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }
    
    return ( <>
        <div className="menu-icon">
        <i className="fas fa-bars"  />
      </div>    
    <div className="header">

    <div className="search">
       <input type="text" className="searchTerm"  placeholder="Search Stuff..."/>
       <button type="submit" className="searchButton">
         <i className="fa fa-search"></i>
      </button>
    </div>

    <div className="header__user">
    
    {user ? (
      <>
        <div><i className="fas fa-user"></i> {firstCharUpper(user.displayName)}</div>
        <div>-</div>
        <div className="header-logout" onClick={logout}>logout</div>
      </>
    ) : (
      <NavLink to="/" className="header-link">
        login
      </NavLink>
    )}
    </div>
    </div>
    </>
    );
}
 
export default withRouter(Header);
