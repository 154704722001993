import React from "react";
import { Link, withRouter } from "react-router-dom";
import FirebaseContext from "../../../Firebase/context";
import Emoji from "../../Emoji/Emoji";
import { PrimaryBtn } from "../../../Styles/Buttons";
import ProjectCard from "../../../Styles/ProjectCard";
import styled from "styled-components";
import moment from "moment"


const Options = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .option-container {
    margin-top: 10px;
  }
  .emoji {
    margin: 10px;
    cursor: pointer;
  }
`;

const ProjectCardItem = ({
  project,
  index,
  showOptions,
  history,
  showDetail
}) => {
  const { firebase, user } = React.useContext(FirebaseContext);

  const projectRef = firebase.db.collection("projects").doc(project.id);
  function handleDeleteProject() {
    projectRef
      .delete()
      .then(() => {
      
      })
      .catch(error => {
       
      });
   // history.push("/dashboard");
  }
  function handleComplete() {
    
          projectRef
          .update({ projectComplete: true })
          .then(() =>{
            
          })
        }
        function handlePending() {
          projectRef
          .update({ pending: true })
          .then(() =>{
            
          })
        }
        function handleReset() {
    
          projectRef
          .update({ projectComplete: false , pending:false})
          .then(() =>{
            
          })
        }
  
  return (
    <ProjectCard>
      <div key={project.id}>
        <h2>{project.companyName}</h2>

        <div>
          <i className="fas fa-phone-square-alt" /> {project.companyPhone}
        </div>
        <div>
          <i className="fas fa-envelope-square" /> {project.companyEmail}
        </div>
        <div>
          <i className="fas fa-tasks" /> {project.category}
        </div>
        <div>
          <i className="fas fa-user" /> {project.addedBy.name}
        </div>
        <div>
        <i class="far fa-calendar-plus"/>{moment(project.created).format("DD/MM/YYYY")}
        </div>
        
        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}><h3 style={{width:"auto"}}>Summary</h3> <span><i className="fas fa-user-cog"></i>{project.assignedTo ? project.assignedTo : null}</span></div>
        <div>{project.projectDescription}</div>
      </div>
      <Options>
        <PrimaryBtn href={`/project/${project.id}`}>
         View Project
        </PrimaryBtn>

        {user && showOptions && (
          <>
            <div className="option-container">
            {project.projectComplete===true || project.pending === true ? <Emoji clicked={handleReset} label="reset" id="reset" symbol="◀️" />:null}
            {project.projectComplete===false ? <Emoji clicked={handleComplete} label="complete" tip="Complete" id="complete" symbol="✅" />:null}
            
            {project.projectComplete === false && project.pending === false? <Emoji clicked={handlePending} label="pending" symbol="🗄️" id="pending"/>:null}
              <Emoji clicked={handleDeleteProject} label="delete" symbol="🗑️" id="delete"/>
            </div>
          </>
        )}
      </Options>
    </ProjectCard>
  );
};

export default withRouter(ProjectCardItem);
