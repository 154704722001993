import React from 'react';
import { FirebaseContext } from '../../../Firebase';
import ProjectCardItem from "../ProjectCardItem/ProjectCardItem"


const SearchProjects = () => {
    const {firebase} = React.useContext(FirebaseContext)
    const [filter,setFilter] = React.useState("")
    const [projects, setProjects] = React.useState([])
  const [filteredProjects, setFilteredProjects] = React.useState([]) 

    React.useEffect(()=>{
        getInitialProjects()
        
    },[])

    function getInitialProjects(){
      firebase.db
      .collection("projects")
      .get()
      .then(snapshot  => {
        const projects = snapshot.docs.map(project =>{
          return{id:project.id, ...project.data()}
        })
        setProjects(projects)
        setFilteredProjects(projects)
      })
    }

function handleSearch(event){
  event.preventDefault()
  const query = filter.toLowerCase()
  const matchProjects = projects.filter(project =>{
    return project.companyName.toLowerCase().includes(query) ||
    project.companyEmail.toLowerCase().includes(query) ||
    project.message.toLowerCase().includes(query)
  })
  setFilteredProjects(matchProjects)
}
    return (<div className="main">
        <form onSubmit={handleSearch} >
          <div>Search<input onChange={event => setFilter(event.target.value)}/>
          </div>
          <button>OK</button>
        </form>
        <div className="project-overview">
        {
          filteredProjects.map((filteredProj, index) =>(
            <ProjectCardItem
            key={filteredProj.id}
          showOptions={false}
          project={filteredProj}
          index={index + 1}
          />
          ))
        }

        </div>
        </div>
      );
}
 
export default SearchProjects