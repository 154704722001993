export default function validateAddProject(values) {
  let errors = {};

  if (!values.companyName) {
    errors.companyName = "Company Name Required";
  } else if (values.companyName.length < 5) {
    errors.companyName = "Please provide the full name";
  }
  //Company Email Errors
  if (!values.companyEmail) {
    errors.companyEmail = "Email Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.companyEmail)
  ) {
    errors.companyEmail = "Invalid email address";
  }

  if (!values.category) {
    errors.category = "Please Choose Category";
  }
  if (!values.companyContactName) {
    errors.companyContactName = "Please add a contact";
  }
  if (values.projectDescription.length < 10) {
    errors.projectDescription = "Please, Please add description";
  }
  return errors;
}
