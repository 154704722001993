import styled from "styled-components";

import { setRem } from "../Styles/helpers";

 const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #82bef6; */
    background-color: #7da4e8;
    /* margin-bottom: 20px; */
    -webkit-column-break-inside: avoid;
    padding:${setRem(24)};
    box-sizing: border-box;
    /* border-radius: 20px; */
    height:fit-content;
    `

export default Card