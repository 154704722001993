import React from "react";
import { Tabs } from "@yazanaabed/react-tabs";
import { withRouter } from "react-router-dom";
import validateAddProject from "../../../Auth/ValidateAddProject";
import "./AddProject.css";
import FirebaseContext from "../../../Firebase/context";
import useFormValidation from "../../../Auth/useFormValidation";
import { AddProjectBtn } from "../../../Styles/Buttons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Emoji from "../../Emoji/Emoji";
import {Formik, Form, Field} from "formik"
import * as Yup from "yup"
import styled from "styled-components"


const AddProjectSchema = Yup.object().shape({
    email: Yup.string().min(10,"10 Character Minimum")
      .required("email is required"),
   
  });
  

const FormOuterStyle = styled.div`
display:grid;
grid-template-rows:1fr;
grid-auto-flow:row;
max-width:600px;
grid-gap: .8em;
    background: #eee;
    padding: 1.2em;
input,textarea,button{
 
    grid-column: controls;
    grid-row: auto;
    border: none;
    padding: 1em;
}
li{
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

`

function AddProjectFormik(props) {
  let INITIAL_PROJECT_STATE = {
    companyName: "",
    companyContactName: "",
    companyEmail: "",
    companyPhone: "",
    category: "",
    projectDescription: "",
    itCompanyName: "",
    itContactName: "",
    itEmail: "",
    itPhone: "",
    itMessage: "",
    erpName: "",
    erpContactName: "",
    erpEmail: "",
    erpPhone: "",
    erpDescription: ""
  };

  const STATE = props.project;

  if (STATE) {
    INITIAL_PROJECT_STATE = STATE;
  }

  const project = props.project;
  const { firebase, user } = React.useContext(FirebaseContext);
  const { handleSubmit, handleChange, values, errors } = useFormValidation(
    INITIAL_PROJECT_STATE,
    validateAddProject,
    handleAddProject
  );

  const options = {
    autoClose: 2000,
    type: toast.TYPE.SUCCESS,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT
    // and so on ...
  };
  function Notify() {
    toast.success("Project has been updated", options);
  }

  function handleAddProject() {
    if (!user) {
      props.history.push("/");
    } else {
      const {
        companyName,
        companyContactName,
        companyEmail,
        companyPhone,
        category,
        projectDescription,
        itCompanyName,
        itContactName,
        itEmail,
        itPhone,
        itMessage,
        erpName,
        erpContactName,
        erpEmail,
        erpPhone,
        erpDescription
      } = values;
      const newProject = {
        companyName,
        companyContactName,
        companyEmail,
        companyPhone,
        category,
        projectDescription,
        addedBy: {
          id: user.uid,
          name: user.displayName
        },
        tasks: [],
        comments: [],
        created: Date.now(),
        itCompanyName,
        itContactName,
        itEmail,
        itPhone,
        itMessage,
        erpName,
        erpContactName,
        erpEmail,
        erpPhone,
        erpDescription,
        projectComplete: false,
        pending:false
      };
      firebase.db.collection("projects").add(newProject);
      for (var key in values) {
        values[key] = "";
      }
    }
  }

  function handleUpdateProject() {
    const {
      companyName,
      companyContactName,
      companyEmail,
      companyPhone,
      category,
      projectDescription,
      itCompanyName,
      itContactName,
      itEmail,
      itPhone,
      itMessage,
      erpName,
      erpContactName,
      erpEmail,
      erpPhone,
      erpDescription
    } = values;
    const updatedProject = {
      companyName,
      companyContactName,
      companyEmail,
      companyPhone,
      category,
      projectDescription,
      addedBy: {
        id: user.uid,
        name: user.displayName
      },
      created: Date.now(),
      itCompanyName,
      itContactName,
      itEmail,
      itPhone,
      itMessage,
      erpName,
      erpContactName,
      erpEmail,
      erpPhone,
      erpDescription
    };
    const projectRef = firebase.db.collection("projects").doc(project.id);
    projectRef.update(updatedProject).then(Notify());
  }

  return (
    <>
      {" "}
      <ToastContainer />
      <FormOuterStyle>
      <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={AddProjectSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form ><ul>
        <li><Field type="text" name="companyName" /></li>
       <li> <Field type="text" name="companyContactName" /></li>
        <li><Field type="email" name="companyEmail" /></li>
        <li><Field type="text" name="companyPhone" /></li>
      
          <li>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && errors.email}
          </li>
          <li>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {errors.password && touched.password && errors.password}
          </li>
          <li>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
          </li>
          </ul>
        </Form>
      )}
    </Formik>
    </FormOuterStyle>
    </>
  );
}

export default withRouter(AddProjectFormik);
