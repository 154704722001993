import React from 'react';
import styled from "styled-components"

const FooterStyle = styled.div`
display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  grid-area: footer;
  background-color: #f19f4d;
`
const Footer = () => {
    return ( <FooterStyle>
    <div className="footer__copyright">&copy; 2019</div>
    <div className="footer__signature">ei project management</div>
    </FooterStyle> );
}
 
export default Footer;

