import styled from "styled-components";

import { setRem } from "./helpers";

const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${setRem(20)};
  background-color: #ffffff;
  /* border-radius: ${setRem(20)}; */
  color: dimgray;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.2s ease-in-out;
  max-height:600px;

  div , h2 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  i {
    margin-right: 10px;
  }
  
  /* transition: all .2s ease-in-out;  */
`;

export default ProjectCard;
