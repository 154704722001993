// EditUserDialog.js
import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import firebase from "../Firebase/firebase";
import styled from "styled-components";
import logo from "../images/eis.png";
import { setFlex, setBackground } from "../Styles/helpers";

const BGContent = styled.div`
  min-height: 100vh;
  ${props => setBackground({ img: props.img, color: "rgba(0,0,0,0)" })}
  ${setFlex({ x: "center", y: "center" })}
`;

const LoginBox = styled.div`
  width: 360px;
  padding: 8% 0 0;
  margin-right: auto;
  margin-left: auto;
`;
const LoginForm = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  /* border-radius: 30px; */
  border:1px solid #ccc;
  background-color: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #ccc;
  }
  button {
    font-family: "Dosis", sans-serif;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    outline: 0;
    background: #f9cf00;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #ffffff;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
`;
const ErrorContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: flex-start;
  color: red;
`;

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .required("Email is required"),
  password: Yup.string()
    .min(9, "Minimum 9 characters")
    .required("Password is required")
});

async function authenticateUser(values, setFieldError, history, login) {
  const { name, email, password } = values;
  try {
    login
      ? await firebase.login(email, password)
      : await firebase.register(name, email, password);
    history.push("/dashboard");
  } catch (error) {
    setFieldError("AuthError", "Authentication Failure");
  }
}

const Login = props => {
  const [login, setLogin] = React.useState(true);
  function handleSubmit(values, { setFieldError }) {
    const { history } = props;
    authenticateUser(values, setFieldError, history,login);
  }
  
  return (
    <>
      <BGContent>
        <LoginBox>
          <Formik
              initialValues={{
                email: "",
                password: ""
              }}
              validationSchema={SigninSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, status, touched, isSubmitting, history }) => (
                <LoginForm >
            <img src={logo} alt="enterprise imaging systems logo" style={{marginBottom:"20px"}} />
            
                <Form>
                {!login && (
                  <Field                  
                    name="name"
                    type="text"
                    placeholder="Your name"
                  />
                )}
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    border={errors.email && "1px solid red"}
                  />
                  <ErrorContainer>
                    {errors.email && touched.email && <div>{errors.email}</div>}
                  </ErrorContainer>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <ErrorContainer>
                    {errors.password && touched.password && (
                      <div>{errors.password}</div>
                    )}
                  </ErrorContainer>

                  <button type="submit">Submit</button>
                  {/*<button
              type="button"
              className="pointer button"
              onClick={() => setLogin(prevLogin => !prevLogin)}
            >
                  {login ? "Sign Up" : "Sign In"}
           </button>*/}
                  <ErrorContainer>
                    {errors.AuthError && <p>{errors.AuthError}</p>}
                  </ErrorContainer>
                </Form> </LoginForm>
              )}
            </Formik>
         
        </LoginBox>
      </BGContent>
    </>
  );
};
export default Login;
