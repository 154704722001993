import React, { useState } from "react";
import  {NavLink} from "react-router-dom"
import './Sidebar.css'
import styled from "styled-components"

const SideBar = styled.div`
grid-area:sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position:fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  z-index:2;
  background-color: #394263;
  transform: translateX(-245px);
  transition:all .6s ease-in-out;
`

const INITIAL_SIDE_STATE = ["Dashboard","My ToDosies"]
const Sidebar = () => {
  const [sideItems] = useState(INITIAL_SIDE_STATE);


  let sideBarItems = sideItems.map(item => (
    <NavLink key={item} to={`/${item.toLowerCase().replace(/\s+/g, '')}`} style={{textDecoration:"none"}}>
      <li key={item} className="sidenav__list-item">
        {item}
      </li>
    </NavLink>
  ));
  // console.log(sideItems);

  return (
    <aside className="sidenav">
      <div className="sidenav__close-icon">
        <i className="fas fa-times" />
      </div>
      <ul className="sidenav__list">{sideBarItems}</ul>
    </aside>
  );
};

export default Sidebar;
