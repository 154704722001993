import styled from "styled-components"

 const ProjectOverview = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,300px));
    /*repeat(auto-fit, minmax(300px, 2fr));
    /* grid-auto-rows: 265px; */
    grid-gap: 40px;
    margin: 20px;

`
export default ProjectOverview