import React from "react";
import { withRouter } from "react-router-dom";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import { PrimaryBtn, CommentBtn } from "../../../Styles/Buttons";
import { ErrorContainer } from "../../../Styles/helpers";
import { FirebaseContext } from "../../../Firebase";
import Card from "../../../Styles/Card";
import Spinner from "../../Spinner/Spinner";
import ProjectOverview from "../../../Styles/ProjectOverview";
import AddProject from "../AddProject/AddProject";
import { Formik, Form, Field } from "formik";
import { setRem, setBorder } from "../../../Styles/helpers";
import * as Yup from "yup";
import styled from "styled-components";
import Select from "react-select";

const ProjectOverviewDetail = styled(ProjectOverview)`
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
`;
const CommentForm = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  color: dimgray;

  textarea {
    display: block;
    padding: 10px;
    resize: none;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    margin-bottom: 10px;
    /* border-radius: 5px; */
    outline: none;
    background-color: #eaf2f9;
    font-size: 15px;
    border: ${props => props.BorderError || "none"};
  }
  label {
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
  }
`;

const Comment = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
  min-width: 200px;
`;
const CommentHeader = styled.div`
  border-bottom: 2px dimgray solid;
  margin-bottom: 0px;
  padding: 10px;
  font-size: 15px;
  margin-top: 0px;
  text-transform: capitalize;
`;
const CommentBody = styled.div`
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 20px;
  font-style: italic;
`;

const CommentItems = styled.div`
  display: flex;
  align-items: center;
  label {
    padding: ${setRem(9)} ${setRem(12)};
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;
const SelectStyle = styled.div`
  width: 200px;
  padding: ${setRem(9)} ${setRem(12)};
  margin-top: 10px;
  margin-bottom: 0px;
`;

const CommentSchema = Yup.object().shape({
  comment: Yup.string()
    .min(10, "10 Character Minimum")
    .required("Comment is required")
});
const USER_INITIAL_STATE = {
  value: "",
  label: ""
};

const ProjectDetail = props => {
  const { firebase, user } = React.useContext(FirebaseContext);
  const projectId = props.match.params.projectId;
  const [project, setProject] = React.useState("");
  const [showComments, setShowComments] = React.useState(false);
  const [projectData, setProjectData] = React.useState("");
  const projectRef = firebase.db.collection("projects").doc(projectId);
  const [userToAssign, setUserToAssign] = React.useState(USER_INITIAL_STATE);


  const options = [
    { value: "Dariusz", label: "Dariusz" },
    { value: "Dylan", label: "Dylan" },
    { value: "Paul", label: "Paul" },
    { value: "Thomas", label: "Thomas" }
  ];

  React.useEffect(() => {
    getLink();
  }, [projectData]);

  function getLink() {
    projectRef.get().then(proj => {
      setProject({ ...proj.data(), id: proj.id });
      setUserToAssign({
        value: proj.data().assignedTo,
        label: proj.data().assignedTo
      });
    });
  }
  const handeSelectChange = selectOption => {
    const user = selectOption.value;
    setUserToAssign(selectOption);

    projectRef.update({ assignedTo: user });
    setProjectData(prevState => ({
      ...prevState,
      assignedTo: user.value
    }));
  };

  function handleAddTask(values) {
    if (!user) {
      props.history.push("/");
    } else {
      projectRef.get().then(proj => {
        if (proj.exists) {
          const previousTasks = proj.data().tasks;
          const task = {
            postedBy: { id: user.uid, name: user.displayName },
            created: Date.now(),
            text: values.comment
          };

          const updatedTasks = [...previousTasks, task];

          projectRef.update({ tasks: updatedTasks });
          setProjectData(prevState => ({
            ...prevState,
            tasks: updatedTasks
          }));
          values.comment = "";
        }
      });
    }
  }

  function handleSetComment() {
    setShowComments(!showComments);
  }
  let commentNodes;
  let buttonText = "Show Comments";
  if (showComments) {
    commentNodes = project.tasks.map((task, index) => (
      <Comment key={index}>
        <CommentHeader>
          {task.postedBy.name} | {distanceInWordsToNow(task.created)}
        </CommentHeader>

        <CommentBody>{task.text}</CommentBody>
      </Comment>
    ));
    buttonText = "Hide Comments";
  }

  function getCommentsTitle(commentCount) {
    if (commentCount === 0) {
      return "No comments yet";
    } else if (commentCount > 1) {
      return commentCount + " comments";
    } else return commentCount + " comment";
  }

  return !project ? (
    <Spinner />
  ) : (
    <>
      <ProjectOverviewDetail>
        <Formik
          initialValues={{
            comment: ""
          }}
          validationSchema={CommentSchema}
          onSubmit={handleAddTask}
        >
          {({ errors, touched }) => (
            <CommentForm>
              <label>Project Comments</label>
              <Form>
                <Field
                  component="textarea"
                  name="comment"
                  rows="6"
                  cols="60"
                  placeholder="Comment:"
                />
                <ErrorContainer>
                  {errors.comment && touched.comment && (
                    <div>{errors.comment}</div>
                  )}
                </ErrorContainer>
                <CommentItems>
                  <CommentBtn type="submit" style={{ marginRight: "10px" }}>
                    Post comment
                  </CommentBtn>
                  <PrimaryBtn type="button" onClick={handleSetComment}>
                    {buttonText}
                  </PrimaryBtn>

                  <label>Assigned To:</label>
                  <SelectStyle>
                    <Select
                      onChange={handeSelectChange}
                      value={userToAssign}
                      name="assigned-user"
                      options={options}
                      isSearchable
                      placeholder="Please Select"
                      theme={theme => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: "lightgrey",
                          primary: "darkgrey"
                        }
                      })}
                    />
                  </SelectStyle>
                </CommentItems>
              </Form>
              <h2 style={{ margin: "20px" }}>
                {getCommentsTitle(project.tasks.length)}
              </h2>
              {commentNodes}
            </CommentForm>
          )}
        </Formik>

        <Card>
          <AddProject mode="edit" project={project} />
        </Card>
      </ProjectOverviewDetail>
    </>
  );
};

export default withRouter(ProjectDetail);
